/**
 * Ramdonbly sorts an array
 * @param {Array} sourceArray
 * @return {Array} randombly sorted copy of sourceArray
 */
const randomizeArray = async (sourceArray) => {
  const copy = [...sourceArray];
  copy.sort(() => Math.random() - 0.5);
  return copy;
};

export default randomizeArray;
