import React, { useEffect, useState } from 'react';
import createTest from '../../services/data/multiplication';
import Question from './question';
import TestProgressStatus from '../testProgressStatus';
import config from '../../services/data/multiplication/config';
const { operation } = config;

const defaultTestProgress = {
  totalQuestions: 0,
  answeredQuestions: 0,
  correctAnswers: 0,
  attempts: 0,
  accuracy: 0
};

const Multiplication = (props) => {
  const [initTest, setInitTest] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [testProgress, setTestProgress] = useState(() => ({
    ...defaultTestProgress
  }));
  const [justAnsweredCorrectlyIndex, setJustAnsweredCorrectlyIndex] = useState(
    () => -1
  );
  const [justAnsweredIncorrectlyIndex, setJustAnsweredIncorrectlyIndex] =
    useState(() => -1);
  const [testCompleted, setTestCompleted] = useState(() => false);
  const [postTestCompleted, setPostCompleted] = useState(() => false);

  const navigateQuestions = (newIndex) => {
    setQuestionIndex(newIndex);
    setJustAnsweredCorrectlyIndex(-1);
    setJustAnsweredIncorrectlyIndex(-1);
  };

  const handleUserAnswer = (question, answer) => {
    const newQuestions = [...questions];
    const index = newQuestions.indexOf(question);
    const isCorrect = question.answer === answer;
    const newQuestion = {
      ...question,
      userAnswer: answer,
      isCorrect,
      isAnswered: true
    };
    const newTestProgress = { ...testProgress };
    if (isCorrect) newTestProgress.correctAnswers++;
    if (!question.isAnswered) newTestProgress.answeredQuestions++;

    newTestProgress.attempts++;
    newTestProgress.accuracy =
      (newTestProgress.correctAnswers * 100) / newTestProgress.attempts;
    newQuestions[index] = newQuestion;

    setTestProgress(newTestProgress);
    setQuestions(newQuestions);
    setJustAnsweredCorrectlyIndex(isCorrect ? questionIndex : -1);
    setJustAnsweredIncorrectlyIndex(isCorrect ? -1 : questionIndex);
    if (isCorrect && questionIndex + 1 < questions.length) {
      setTimeout(() => {
        setQuestionIndex(questionIndex + 1);
      }, 4000);
    }
    if (newTestProgress.answeredQuestions === newQuestions.length) {
      setTestCompleted(true);
      setTimeout(() => setPostCompleted(true), 4000);
    }
  };

  useEffect(() => {
    createTest(window.location.href)
      .then((newTest) => {
        setQuestions(newTest.questions);
        setQuestionIndex(0);
        setJustAnsweredCorrectlyIndex(-1);
        setJustAnsweredIncorrectlyIndex(-1);
        setTestCompleted(false);
        setPostCompleted(false);
        setTestProgress({
          ...defaultTestProgress,
          totalQuestions: newTest.questions.length
        });
      })
      .catch((err) => console.error(err));
  }, [initTest]);

  if (!questions || !questions.length || questions.length < 1) {
    return (
      <div>
        <h1>{operation}</h1>
      </div>
    );
  }

  const question = questions[questionIndex];
  return (
    <div className="component">
      <div className="shadow align-content-start">
        {!postTestCompleted && (
          <Question
            key={question.id}
            question={question}
            handleUserAnswer={handleUserAnswer}
            title={`Question ${questionIndex + 1} of ${questions.length}`}
            justAnsweredCorrectly={justAnsweredCorrectlyIndex === questionIndex}
            justAnsweredIncorrectly={
              justAnsweredIncorrectlyIndex === questionIndex
            }
            questionIndex={questionIndex}
            total={questions.length}
            navigate={navigateQuestions}
          />
        )}
        {testCompleted && postTestCompleted && (
          <div className="alert alert-primary" role="alert">
            Completed!
          </div>
        )}

        <TestProgressStatus testProgress={testProgress} />

        {testCompleted && postTestCompleted && (
          <button
            type="button"
            className="btn btn-link m-2"
            onClick={() => setInitTest(initTest + 1)}
          >
            Play Again
          </button>
        )}
      </div>
    </div>
  );
};

export default Multiplication;
