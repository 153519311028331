import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getDivisors from '../../services/getDivisors';

const width = {
  width: 20,
  size: 7
};

const Divisor = ({ num, userIsDoneWithQuestion = false, onClick }) => {
  const [divisors, setDivisors] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    getDivisors(num)
      .then((newDivisors) => setDivisors(newDivisors))
      .then(() => setLoaded(true))
      .catch((err) => console.error(err));
  }, [loaded]);

  return (
    <div className="d-inline border border-light rounded">
      <div className="d-flex">
        <div className="m-1">
          <button
            type="button"
            className="btn btn-info m-1"
            disabled={userIsDoneWithQuestion}
            style={{ minWidth: 44 }}
            onClick={() => onClick(num)}
          >
            {num}
          </button>
        </div>
        <div className="d-sm mr-auto m-1 ">
          {divisors.map((e) => (
            <span
              key={e}
              className="badge badge-warning ml-1"
              style={width}>
              <small>{e}</small>
            </span>
          ))}
          {showMore &&
            divisors.map((e) => (
              <div key={e} className="d-flex d-sm">
                <span className="badge badge-dark ml-1 mt-1" style={width}>
                  <small>{e}</small>
                </span>
                <span className="badge badge-light ml-1 mt-1" style={width}>
                  x
                </span>
                <span className="badge badge-dark ml-1 mt-1" style={width}>
                  <small>{num / e}</small>
                </span>
                <span className="badge badge-light ml-1 mt-1" style={width}>
                  =
                </span>
                <span className="badge badge-dark ml-1 mt-1" style={width}>
                  <small>{num}</small>
                </span>
              </div>
            ))}
        </div>
        <div className="m-1">
          {divisors.length > 0 && (
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? <span>&larr; less</span> : <span>more &darr;</span>}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Divisor;

Divisor.propTypes = {
  num: PropTypes.number.isRequired,
  userIsDoneWithQuestion: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
