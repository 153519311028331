import config from './config';
import createQuestions from './createQuestions';
import randomizeArray from '../../../utils/randomizeArray';
import injectMultipleChoices from './injectMultipleChoices';

const { operation, operator } = config;

/**
 * Creates a test object
 * @param {String} url Browser's URL
 * @return {Object} { operation, operator, questions }
 */
const createTest = async (url) => {
  console.log({ url });
  const limit = `${url}`.match(/localhost/i) ? 2 : null;
  const sortedQuestions = await createQuestions(2, 2);
  const scrumbled = await randomizeArray(sortedQuestions);
  const questions = await injectMultipleChoices(
    limit ? scrumbled.slice(0, limit) : scrumbled
  );
  const testInstance = {
    operation, operator, questions
  };
  return testInstance;
};

export default createTest;
