import React from 'react';
import PropTypes from 'prop-types';

const QuestionNavigator = ({ index, total, navigate }) => {
  const nextIndex = index < total - 1 ? index + 1 : null;
  const prevIndex = index > 0 ? index - 1 : null;
  return (
    <div className="text-center">
      <button
        type="button"
        className="btn btn-link m-2"
        disabled={prevIndex === null}
        onClick={() => navigate(prevIndex)}
      >
        &laquo; Previous
      </button>
      <button
        type="button"
        className="btn btn-link m-2"
        disabled={nextIndex === null}
        onClick={() => navigate(nextIndex)}
      >
        Next &raquo;
      </button>
    </div>
  );
};

export default QuestionNavigator;

QuestionNavigator.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired
};
