import randomizeArray from '../../../utils/randomizeArray';

const processQuestion = async (question) => {
  const { answer } = question;
  const answerPool = {};
  for (let i = 1; i < 8; i++) {
    const x = answer + i;
    const y = answer - i;
    if (x >= 0) {
      answerPool[x] = true;
    }
    if (y >= 0) {
      answerPool[y] = true;
    }
  }
  delete answerPool[answer];

  const badAnswers = await randomizeArray(Object.keys(answerPool));
  const tempChoices = [
    Number(badAnswers.shift()),
    Number(badAnswers.pop()),
    answer,
    Number(badAnswers.shift())
  ];
  const choices = await randomizeArray(tempChoices);
  const result = {
    ...question,
    choices
  };
  return result;
};

const injectMultipleChoices = async (questions) => {
  const queue = [];
  questions.forEach((question) => {
    const task = processQuestion(question);
    queue.push(task);
  });
  const newQuestions = await Promise.all(queue);
  return newQuestions;
};

export default injectMultipleChoices;
