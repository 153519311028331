import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divisor from './divisor';
import QuestionNavigator from '../questionNavigator';
import { CheckLg } from 'react-bootstrap-icons';

const width = {
  width: 25
};

const Question = ({
  question,
  handleUserAnswer,
  title,
  justAnsweredCorrectly,
  justAnsweredIncorrectly,
  questionIndex,
  total,
  navigate
}) => {
  const [showHelp, setShowHelp] = useState(false);
  const { a, b, operator, operation, choices, isCorrect, isAnswered, answer } =
    question;
  const userIsDoneWithQuestion = isCorrect && isAnswered;

  const handleAnswerFromUser = (value) => {
    handleUserAnswer(question, value);
  };

  return (
    <div className="shadow-lg border border-dark rounded p-2 m-2">
      <div className="rounded bg-info p-2 m-1 text-center">
        <div className="h2">{operation}</div>
        <div className="h6">{title}</div>
      </div>

      {justAnsweredCorrectly ? (
        <div className="bg-light rounded m-1 p-2 text-center h2 text-info">
          <div className="h1">
            <CheckLg size={75} className="m-1 p-2 text-info" />
            Correct!
          </div>
          <span className="ml-2">{a}</span>
          <span className="ml-2">{operator}</span>
          <span className="ml-2">{b}</span>
          <span className="ml-2">=</span>
          <span
            className="ml-2 border-bottom border-dark text-center"
            style={{ minWdth: 30 }}
          >
            {answer}
          </span>
        </div>
      ) : (
        <div className="bg-light rounded m-1 p-2 text-center h2">
          <span className="m-2">{a}</span>
          <span className="m-2">{operator}</span>
          <span className="m-2">{b}</span>
          <span className="m-2">=</span>
          <span
            className="m-2 border-bottom border-dark text-center"
            style={{ minWdth: 30 }}
          >
            {userIsDoneWithQuestion && answer}
          </span>
          <span className="text-right" style={width}>
            <button
              type="button"
              className="btn btn-warning m-1 "
              onClick={() => setShowHelp(!showHelp)}
            >
              ?
            </button>
          </span>
          <div className="text-center">
            {!showHelp && choices.map((value) => (
              <button
                key={value}
                type="button"
                className="btn btn-info m-2"
                disabled={userIsDoneWithQuestion}
                style={{ minWidth: 44 }}
                onClick={(e) => handleAnswerFromUser(value) }
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      )}
      {justAnsweredIncorrectly && (
        <div className="alert alert-warning text-center" role="alert">
          That is not the correct answer. Please try again.
        </div>
      )}
      {showHelp && (
        <div className="d-flex flex-column border p-1 rounded">
          <div className="h4 mt-2">Divisors:</div>
          {choices.map((value) => (
            <Divisor
              key={value}
              num={value}
              userIsDoneWithQuestion={userIsDoneWithQuestion}
              onClick={handleAnswerFromUser} />
          ))}
          {a !== b && (
            <div>
              <div className="h4 mt-1 mr-3">Same as:</div>
              <span className="badge badge-warning m-1 ml-5" style={width}>
                {b}
              </span>
              <span className="badge badge-light m-1" style={width}>
                {operator}
              </span>
              <span className="badge badge-warning m-1" style={width}>
                {a}
              </span>
            </div>
          )}
        </div>
      )}
      <QuestionNavigator
        index={questionIndex}
        total={total}
        navigate={navigate}
      />
    </div>
  );
};

export default Question;

Question.propTypes = {
  question: PropTypes.object.isRequired,
  handleUserAnswer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  justAnsweredCorrectly: PropTypes.bool,
  justAnsweredIncorrectly: PropTypes.bool,
  questionIndex: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired
};
Question.defaultProps = {
  justAnsweredCorrectly: false,
  justAnsweredIncorrectly: false
};
