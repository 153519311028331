import React from 'react';
import PropTypes from 'prop-types';

const TestProgressStatus = ({ testProgress }) => {
  const {
    totalQuestions,
    answeredQuestions,
    attempts,
    correctAnswers,
    accuracy
  } = testProgress;

  const progressPercent = (answeredQuestions * 100) / totalQuestions;
  if (!totalQuestions) return null;

  return (
    <div className="shadow-lg border border-dark rounded p-1 m-2">
      <div className="progress bg-secondary" style={{ height: 5 }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercent}%` }}
          aria-valuenow={progressPercent}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex">
        <div className="mr-auto p-1">Answered Questions</div>
        <div className="p-1">
          {answeredQuestions}
          &nbsp; of &nbsp;
          {totalQuestions}
        </div>
      </div>

      <div className="d-flex">
        <div className="mr-auto p-1">Correct Answers</div>
        <div className="p-1">{correctAnswers}</div>
      </div>

      <div className="d-flex">
        <div className="mr-auto p-1">Attempts</div>
        <div className="p-1">{attempts}</div>
      </div>

      <div className="d-flex">
        <div className="mr-auto p-1">Accuracy</div>
        <div className="p-1">{`${Number(accuracy).toFixed(1)}%`}</div>
      </div>
    </div>
  );
};

export default TestProgressStatus;

TestProgressStatus.propTypes = {
  testProgress: PropTypes.object.isRequired
};
