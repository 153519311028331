import config from './config';

const { operation, operator } = config;

/**
 * Creates possible questions with unique
 * combinations where X * Y and Y * X are
 * treated as one.
 * @param {Number} minX Starts with X
 * @param {Number} minY Starts with Y
 * @return {Array} Array of questions
 */
const createQuestions = async (minX = 0, minY = 0) => {
  const questions = {};
  if (!(minX >= 0 && minY >= 0 && minX <= 9 && minY <= 9)) {
    throw new Error('minX and minY must be numbers between 0 and 9');
  }

  for (let a = minX; a <= 9; a++) {
    for (let b = minX; b <= 9; b++) {
      const id = `${a}_${operator}_${b}`;
      const id2 = `${b}_${operator}_${a}`;
      const question = {
        id,
        a,
        b,
        answer: a * b,
        operation,
        operator
      };
      if (questions[id2]) {
        questions[id2] = question;
      } else {
        questions[id] = question;
      }
    }
  }
  const result = Object.values(questions);
  return result; // result.slice(0, 10);
};

export default createQuestions;
