/**
 * Calculates the divisors of integers not 0 or 1
 * @param {Number} num
 * @return {Array<Number>} divisors
 */
const getDivisor = async (num) => {
  // eslint-disable-next-line radix
  const val = parseInt(num);
  if (val < 1) return [];
  const uniqueKeys = {};
  let limit = val;
  for (let i = 1; i < limit; i++) {
    if (val % i === 0) {
      const x = val / i;
      uniqueKeys[i] = true;
      uniqueKeys[x] = true;
      limit = x;
    }
  }
  const ret = [];
  Object.keys(uniqueKeys).forEach((n) => ret.push(Number(n)));
  ret.sort((a, b) => a - b);
  return ret;
};

export default getDivisor;
